<template>
  <div class="modal-wrapper" @click="close"></div>
    <div class="modal-wrap" v-if="isLoaded">
      <article>
        <div class="sub-title">
          <h2>{{$t('betting.detail.title')}}</h2>
          <button class="btn" type="button" @click="close"><i class="fa fa-times"></i></button>
        </div>
        <div class="page-content">
          <div class="content" v-if="detailData">
            <p>
              <div>
                <component :is="component" :detailData="detailData" :dType="dType">
                </component>
              </div>
            </p>
          </div>
          <div class="content" v-else>
            <p>
              <span>
                {{$t('betting.detail.noData')}}
              </span>
            </p>
          </div>
        </div>
      </article>
    </div>
</template>

<script>
import { getBettingDetail } from '@/api/bet'
import { defineAsyncComponent } from 'vue'
import { insureBetRealBotDetail } from '@/api/insured'

export default {
  name: 'betDetail',
  props: [
    'meta',
    'type'
  ],
  emits: ['close'],
  data () {
    return {
      detailData: null,
      isLoaded: false,
      dType: null
    }
  },
  computed: {
    component () {
      let vendorCode = this.meta.vendorCode
      if (!['1', '2', '9', '13', '200', '213', '214', '216', 'powerball'].includes(vendorCode)) {
        vendorCode = 'Etc'
      }
      console.log(vendorCode, this.detailData)
      if (vendorCode === '1' && this.detailData && this.detailData.vendorCode && this.detailData.vendorCode === 'mon_evoltion') {
        vendorCode = vendorCode + 'Mon'
        console.log(vendorCode)
      }
      if (vendorCode === '1' && this.detailData && this.detailData[0]) {
        vendorCode = vendorCode + 'Nexus'
      }
      return defineAsyncComponent(() => import('@/views/member/bet/pages/detail/vendorCode' + vendorCode))
    }
  },
  methods: {
    async getDetailInsureData () {
      this.emitter.emit('Loading', true)
      const params = {
        siteId: this.meta.siteId,
        betId: this.meta.betId,
        vendorCode: this.meta.vendorCode
      }
      this.isLoaded = false

      await insureBetRealBotDetail(params).then(res => {
        if (res.resultCode === '0') {
          this.detailData = JSON.parse(res.data.detail)
          console.log('detailData :', this.detailData)
        }
        this.isLoaded = true
        this.emitter.emit('Loading', false)
      }).catch(e => {
        this.isLoaded = true
        this.emitter.emit('Loading', false)
      })
    },

    async getDetailData () {
      this.emitter.emit('Loading', true)
      const params = {
        betType: this.meta.betType,
        betId: this.meta.gameIdx
      }
      this.isLoaded = false

      await getBettingDetail(params).then(res => {
        if (res.resultCode === '0') {
          const dataItem = JSON.parse(res.data.detail)
          //this.detailData = JSON.parse(res.data.detail)
          this.detailData = dataItem.detail
          this.dType = dataItem.dType
          console.log('detailData :', this.detailData)
          console.log('dType :', this.dType)
        }
        this.isLoaded = true
        this.emitter.emit('Loading', false)
      }).catch(e => {
        this.isLoaded = true
        this.emitter.emit('Loading', false)
      })
    },
    close () {
      this.$emit('close', true)
    }
  },
  async created () {
    console.log('detail bet', this.meta)
    if (this.type === 'insure') {
      await this.getDetailInsureData()
    } else {
      await this.getDetailData()
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  display: flex;
}
.modal-wrap {
  padding: 0;
  width: 100%;
  max-width: 1040px;
  min-width: 550px;
  z-index: 101;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0px;
  background: #3d3d3d;
  border-color: #3d3d3d;
}
.page-contents-wrap {
  padding: 0;
  background: #353535;
}
.sub-title {
  color: #fff;
  font-size: 14px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 2px solid transparent;
}
.sub-title .btn {
  position: absolute;
  right: 15px;
  background: no-repeat;
  font-size: 22px;
  width: auto;
  height: auto;
  cursor: pointer;
}
.page-content {
  padding: 27px 20px 25px;
  color: #fff;
  max-height: 820px;
  overflow: auto;
}
.page-content .content {
  text-align: center;
}
.page-content .content p {
  font-size: 14px;
  text-align: left;
  margin: 10px 0;
}
.page-content .content p span {
  margin-left: 20px;
  display: block;
  border-radius: 18px;
  padding: 1px 12px;
}
.page-content .content p span.normal {
  background: #0f84ee;
}
.page-content .content p span.black {
  background: #808080;
}
.page-content .content p span.stop {
  background: #e1534e;
}
.page-content .content .setting {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
  text-align: center;
}
.page-content .content .setting em {
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
}
.page-content .content .btnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.page-content .content .btnWrap a {
  width: 168px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.page-content .content .btnWrap a.btn {
  border: 1px solid #a4a4a4;
  box-sizing: border-box;
  background: #545454;
}
.page-content .content .input {
  border: 1px solid #353535;
  font-size: 14px;
  width: 356px;
  height: 30px;
  margin-top: 20px;
}
.page-content .content .input::placeholder,
.page-content .content textarea::placeholder {
  color: #a4a4a4;
}
.page-content .content textarea {
  resize: none;
  width: 480px;
  height: 112px;
  padding: 10px 12px;
  font-size: 14px;
}
.modal-wrap .sub-title {
  border-color: #e1534e;
}
.modal-wrap .sub-title .btn {
  color: #e1534e;
}
.modal-wrap .btnWrap a {
  background: #e1534e;
}

</style>

<style>
.detailData {border-top: 1px solid #484848;border-right: 1px solid #484848;box-sizing: border-box;}
.detailData ul {display: flex;flex-wrap: wrap;}
.detailData ul li {width: 70%;padding: 10px;display: flex;align-items: center;border-bottom: 1px solid #484848;border-left: 1px solid #484848;box-sizing: border-box;line-height: 1.5em;}
.detailData ul li:first-child {width: 30%;}
.detailData ul li > span {padding: 0 !important;margin: 0 0 5px !important;}
.detailData .data {flex-direction: column;align-items: flex-start;}
.dataname {margin: 20px 0;}
.card {display: flex;gap: 8px;}
.card em {width: 34px;height: 51px;display: block;text-indent: -9999999px;}
.CAS, .s_1 {background: url(~@/assets/img/card/s_a.svg);background-size: cover;}
.C2S, .s_2 {background: url(~@/assets/img/card/s_2.svg);background-size: cover;}
.C3S, .s_3 {background: url(~@/assets/img/card/s_3.svg);background-size: cover;}
.C4S, .s_4 {background: url(~@/assets/img/card/s_4.svg);background-size: cover;}
.C5S, .s_5 {background: url(~@/assets/img/card/s_5.svg);background-size: cover;}
.C6S, .s_6 {background: url(~@/assets/img/card/s_6.svg);background-size: cover;}
.C8S, .s_7 {background: url(~@/assets/img/card/s_8.svg);background-size: cover;}
.C7S, .s_8 {background: url(~@/assets/img/card/s_7.svg);background-size: cover;}
.C9S, .s_9 {background: url(~@/assets/img/card/s_9.svg);background-size: cover;}
.CTS, .s_10 {background: url(~@/assets/img/card/s_10.svg);background-size: cover;}
.CJS, .s_11 {background: url(~@/assets/img/card/s_j.svg);background-size: cover;}
.CQS, .s_12 {background: url(~@/assets/img/card/s_q.svg);background-size: cover;}
.CKS, .s_13 {background: url(~@/assets/img/card/s_k.svg);background-size: cover;}

.CAC, .c_1 {background: url(~@/assets/img/card/c_a.svg);background-size: cover;}
.C2C, .c_2 {background: url(~@/assets/img/card/c_2.svg);background-size: cover;}
.C3C, .c_3 {background: url(~@/assets/img/card/c_3.svg);background-size: cover;}
.C4C, .c_4 {background: url(~@/assets/img/card/c_4.svg);background-size: cover;}
.C5C, .c_5 {background: url(~@/assets/img/card/c_5.svg);background-size: cover;}
.C6C, .c_6 {background: url(~@/assets/img/card/c_6.svg);background-size: cover;}
.C7C, .c_7 {background: url(~@/assets/img/card/c_7.svg);background-size: cover;}
.C8C, .c_8 {background: url(~@/assets/img/card/c_8.svg);background-size: cover;}
.C9C, .c_9 {background: url(~@/assets/img/card/c_9.svg);background-size: cover;}
.CTC, .c_10 {background: url(~@/assets/img/card/c_10.svg);background-size: cover;}
.CJC, .c_11 {background: url(~@/assets/img/card/c_j.svg);background-size: cover;}
.CQC, .c_12 {background: url(~@/assets/img/card/c_q.svg);background-size: cover;}
.CKC, .c_13 {background: url(~@/assets/img/card/c_k.svg);background-size: cover;}

.CAH, .h_1 {background: url(~@/assets/img/card/h_a.svg);background-size: cover;}
.C2H, .h_2 {background: url(~@/assets/img/card/h_2.svg);background-size: cover;}
.C3H, .h_3 {background: url(~@/assets/img/card/h_3.svg);background-size: cover;}
.C4H, .h_4 {background: url(~@/assets/img/card/h_4.svg);background-size: cover;}
.C5H, .h_5 {background: url(~@/assets/img/card/h_5.svg);background-size: cover;}
.C6H, .h_6 {background: url(~@/assets/img/card/h_6.svg);background-size: cover;}
.C7H, .h_7 {background: url(~@/assets/img/card/h_7.svg);background-size: cover;}
.C8H, .h_8 {background: url(~@/assets/img/card/h_8.svg);background-size: cover;}
.C9H, .h_9 {background: url(~@/assets/img/card/h_9.svg);background-size: cover;}
.CTH, .h_10 {background: url(~@/assets/img/card/h_10.svg);background-size: cover;}
.CJH, .h_11 {background: url(~@/assets/img/card/h_j.svg);background-size: cover;}
.CQH, .h_12 {background: url(~@/assets/img/card/h_q.svg);background-size: cover;}
.CKH, .h_13 {background: url(~@/assets/img/card/h_k.svg);background-size: cover;}

.CAD, .d_1 {background: url(~@/assets/img/card/d_a.svg);background-size: cover;}
.C2D, .d_2 {background: url(~@/assets/img/card/d_2.svg);background-size: cover;}
.C3D, .d_3 {background: url(~@/assets/img/card/d_3.svg);background-size: cover;}
.C4D, .d_4 {background: url(~@/assets/img/card/d_4.svg);background-size: cover;}
.C5D, .d_5 {background: url(~@/assets/img/card/d_5.svg);background-size: cover;}
.C6D, .d_6 {background: url(~@/assets/img/card/d_6.svg);background-size: cover;}
.C7D, .d_7 {background: url(~@/assets/img/card/d_7.svg);background-size: cover;}
.C8D, .d_8 {background: url(~@/assets/img/card/d_8.svg);background-size: cover;}
.C9D, .d_9 {background: url(~@/assets/img/card/d_9.svg);background-size: cover;}
.CTD, .d_10 {background: url(~@/assets/img/card/d_10.svg);background-size: cover;}
.CJD, .d_11 {background: url(~@/assets/img/card/d_j.svg);background-size: cover;}
.CQD, .d_12 {background: url(~@/assets/img/card/d_q.svg);background-size: cover;}
.CKD, .d_13 {background: url(~@/assets/img/card/d_k.svg);background-size: cover;}
</style>
